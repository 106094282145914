.code-page-container {
  display: flex;
  height: 100vh;
  background-color: #f0f0f0;
}

.form-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.form-container {
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.change-email-link {
  color: #6a0dad;
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
  text-decoration: underline;
}

.change-email-link:hover {
  text-decoration: none;
}

.form-container h1 {
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: bold;
}

.form-container p {
  margin-bottom: 24px;
  font-size: 14px;
  color: #555555;
}

.form-container label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.form-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.form-container button {
  width: 100%;
  padding: 12px;
  background-color: #6a0dad;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6a0dad;
  color: #ffffff;
}

.info-section h2 {
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.info-section p {
  margin-bottom: 24px;
  font-size: 18px;
}

.designer-info {
  display: flex;
  align-items: center;
}

.designer-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
}
