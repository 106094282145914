.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.reactflow-wrapper {
  background: #f7f7f7;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

/* .dndflow .dndnode {
    height: 20px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
  }
  
  .dndflow .dndnode.input {
    border-color: #0041d0;
  } */

.dndflow .dndnode.input button {
  width: 100%;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.textarea-resize {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 8px;
}

.react-flow__node {
  z-index: -1 !important;
}

.react-flow__node-ResizableNodeSelected {
  text-align: center;
}

/* ul{
  padding-left: 30px;
 } */
.formPreview ul {
  background-color: #fff;
}
.formPreview > ul {
  margin-bottom: 20px;
  background-color: #f2f2f2;
}

.textarea-resize.error {
  border-color: red;
}

.que-container {
  margin-bottom: 8px;
}

.form-field-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.form-field-container label {
  font-size: 14px;
}

.formPreview-container .form-add-btn {
  display: none !important;
}

.json-preview-container {
  display: flex;
}
