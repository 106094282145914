@font-face {
    font-family: 'Sen-Bold';
    src: url('./Sen-Bold.eot');
    src: url('./Sen-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Sen-Bold.woff2') format('woff2'),
        url('./Sen-Bold.woff') format('woff'),
        url('./Sen-Bold.ttf') format('truetype'),
        url('./Sen-Bold.svg#Sen-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sen-Regular';
    src: url('./Sen-Regular.eot');
    src: url('./Sen-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Sen-Regular.woff2') format('woff2'),
        url('./Sen-Regular.woff') format('woff'),
        url('./Sen-Regular.ttf') format('truetype'),
        url('./Sen-Regular.svg#Sen-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sen-ExtraBold';
    src: url('./Sen-ExtraBold.eot');
    src: url('./Sen-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./Sen-ExtraBold.woff2') format('woff2'),
        url('./Sen-ExtraBold.woff') format('woff'),
        url('./Sen-ExtraBold.ttf') format('truetype'),
        url('./Sen-ExtraBold.svg#Sen-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sen-SemiBold';
    src: url('./Sen-SemiBold.eot');
    src: url('./Sen-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Sen-SemiBold.woff2') format('woff2'),
        url('./Sen-SemiBold.woff') format('woff'),
        url('./Sen-SemiBold.ttf') format('truetype'),
        url('./Sen-SemiBold.svg#Sen-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sen-Medium';
    src: url('./Sen-Medium.eot');
    src: url('./Sen-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Sen-Medium.woff2') format('woff2'),
        url('./Sen-Medium.woff') format('woff'),
        url('./Sen-Medium.ttf') format('truetype'),
        url('./Sen-Medium.svg#Sen-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

