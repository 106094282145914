.form-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.footer-line.md\:text-left.text-center.p-\[10px\].md\:pt-\[20px\].md\:pb-\[25px\].md\:px-8.fixed.bottom-0.w-full.md\:w-\[calc\(100\%_-_240px\)\].md\:flex.block.items-center.justify-between {
  position: fixed;
  bottom: 0;
  padding: 16px;
  background: #fff;
}

.login-form h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.login-header {
  text-align: right;
  margin-bottom: 1rem;
}

.info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6a0dad;
  color: #ffffff;
}

.info-section h2 {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.info-section p {
  margin-bottom: 24px;
  font-size: 14px;
}

.designer-info {
  display: flex;
  align-items: center;
}

.designer-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
}
.btn_verification {
  background: rgb(37, 87, 179) !important;
}
